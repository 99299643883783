import { Routes, Route, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { Analytics } from "@vercel/analytics/react"

import About from './about';

import TrackerPublic from './tracker-public';
// import TrackerKRNLS from './pages/tracker-krnls-wip';
import TrackerKRNLS from './tracker-krnls';
import TrackerLive from './tracker-live';

function App() {

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Improved mobile detection logic
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice = /android|iPad|iPhone|iPod|IEMobile|Opera Mini/.test(userAgent) && !window.MSStream;
    setIsMobile(isMobileDevice);
  }, []);

    const appStyle = {
      textAlign: 'center',
      backgroundColor: '#f0f0f0',
      padding: '0px',
  };
  const navItems = [
    { path: "/about", label: "About" },
    { path: "/tracker-public", label: "TrackerPublic" },
    { path: "/tracker-live", label: "TrackerLive" },
    { path: "/tracker-krnls", label: "TrackerKrnls" },
];
  return (
    <div className="App" style={appStyle}>
        {isMobile ? (
            <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f8d7da', color: '#721c24' }}>
                <p>Sorry bro! desktop only for now.</p>
            </div>
        ) : (
            <>
                <nav style={{  position: 'fixed', top: '3%', zIndex: 1000, width: '100%' }}>
                    {navItems.map(item => (
                        <span key={item.path} style={{ marginRight: '10px', backgroundColor: item.path === "/tracker-live" ? 'green' : 'black', color: 'white', padding: '10px', borderRadius: '5px' , fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
                            <Link to={item.path} style={{ color: 'white', textDecoration: 'none' }}>{item.label}</Link>
                        </span>
                    ))}
                </nav>
                <Routes>
                    <Route path="/" element={<TrackerPublic />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/tracker-public" element={<TrackerPublic />} />
                    <Route path="/tracker-live" element={<TrackerLive />} />
                    <Route path="/tracker-krnls" element={<TrackerKRNLS />} />
                </Routes>
                <div> 
                    <Analytics />
                </div>
            </>
        )}
    </div>
  );
}



export default App;

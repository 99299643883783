import React, { useEffect, useState } from 'react';

const About = () => {
    const [solanaPrice, setSolanaPrice] = useState(null);

    useEffect(() => {
        const fetchSolanaPrice = async () => {
            try {
                const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd');
                const data = await response.json();
                setSolanaPrice(data.solana.usd);
            } catch (error) {
                console.error('Error fetching Solana price:', error);
            }
        };

        fetchSolanaPrice();
    }, []);

    return (
        <>
        <div style={{ marginTop: '8%' }}></div>

            <h1>LFG KERNELS DOT FUN</h1>
            <h5>BUILDING BUNCH OF TOOLS TO HELP ESCAPE THE MATRIX</h5>
            <h4> Kernels N01 <a href="https://magiceden.us/marketplace/krnlsn01"> magic eden </a>
                  |  Kernels WAGMI <a href="https://magiceden.us/marketplace/kenels_wagmi"> magic eden</a> </h4>
            <h5 style={{color: 'deeppink'}}> Grab a kernel for access, support, community, and more!</h5>
            {/* <img src="/AD.png" alt="AD" style={{ width: '200px', height: '200px' }}/> */}
            <h4> dev <a href="https://twitter.com/findbleh"> @findbleh </a> <br></br> 
            project <a href="https://twitter.com/kernelsNFT"> @KernelsNFT </a> | <a href="https://twitter.com/kernelsTools"> @KernelsTools</a> </h4>
            <h2> may the force be with you</h2>
            <h2> have fun</h2>
            <h2> WAGMI </h2> 


        </>
    );
}

export default About;

